<template>
  <div class="margin-top-30 padding-bottom-30">
    <div style="margin: 0 5vw;">
      <div class="grid-content-div">
        <div class="div-item"><h3 class="inline-block">核心数据</h3> <span class="margin-left-30">更新时间：{{updateTimeStr}}</span> <el-button class="margin-left-10" type="text" @click="getKernelStaticData">刷新</el-button> </div>
        <div class="div-item line-border padding-30 text-center" title="">
          <div class="flex">
            <div class="flex-1">
              <div class="margin-bottom-10">
                <span class="f18">支付金额</span>
              </div>
              <div class="margin-bottom-10"><span class="f30 color-orange">￥{{theDateKernelStatisticData.payAmount || 0.00}}</span></div>
              <div><span>昨日 </span><span class="margin-left">￥{{lastDateKernelStatisticData.payAmount || 0.00}}</span></div>
            </div>
            <div class="flex-1">
              <div class="margin-bottom-10">
                <span class="f18">订单数</span>
              </div>
              <div class="margin-bottom-10"><span class="f30 color-orange">{{theDateKernelStatisticData.orderCount || 0}}</span></div>
              <div><span>昨日 </span><span class="margin-left">{{lastDateKernelStatisticData.orderCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="margin-bottom-10">
                <span class="f18">支付人数</span>
              </div>
              <div class="margin-bottom-10"><span class="f30 color-orange">{{theDateKernelStatisticData.buyerCount || 0}}</span></div>
              <div><span>昨日 </span><span class="margin-left">{{lastDateKernelStatisticData.buyerCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="margin-bottom-10">
                <span class="f18">访客数</span>
              </div>
              <div class="margin-bottom-10"><span class="f30 color-orange">{{theDateKernelStatisticData.visitorCount || 0}}</span></div>
              <div><span>昨日 </span><span class="margin-left">{{lastDateKernelStatisticData.visitorCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="margin-bottom-10">
                <span class="f18">浏览量</span>
              </div>
              <div class="margin-bottom-10"><span class="f30 color-orange">{{theDateKernelStatisticData.viewsCount || 0}}</span></div>
              <div><span>昨日 </span><span class="margin-left">{{lastDateKernelStatisticData.viewsCount || 0}}</span></div>
            </div>
          </div>
        </div>
        <div class="div-item"><h3>交易分析</h3></div>
        <div class="div-item">
          <span>统计时间</span>
          <el-select v-model="searchData.timeType"
                     @change="changeTimeType"
                     placeholder="时间类型"
                     style="width: 120px;margin-left: 15px;">
            <el-option
              v-for="item in timeTypeArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker
            :clearable="false"
            :editable="false"
            key="multiSelect"
            style="margin-left: 15px;"
            range-separator="~"
            class="date-picker-pointer"
            value-format="yyyy-MM-dd"
            @change="changeTime"
            v-model="searchData.startTime"
            :picker-options="timeTypeArray[searchData.timeType-1].option"
            :format="timeTypeArray[searchData.timeType-1].format"
            :readonly="timeTypeArray[searchData.timeType-1].readonly"
            :type="timeTypeArray[searchData.timeType-1].type"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="div-item">
          <div style="height: 470px;position: relative;padding-right: 25%;">
            <div class="left inline-block" style="width:100%;height: 470px;position: relative;">
              <div class="new-border-test"><div class="arrows-left"></div></div>
              <div class="new-border-test"><div class="arrows-left"></div></div>
              <div class="new-border-test"><div class="arrows-left"></div></div>
              <div class="trade-item-wrap" style="background-color: #ccedfc;">
                <div class="flex-1">
                  <div class="f18">
                    <span>访客数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，店铺所有页面（包括店铺主页、单品页）被访问的去重人数，一个人在筛选时间范围内访问多次只记为一个" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.visitorCount || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.visitorCount || 0}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>浏览量</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，店铺所有页面（包括店铺主页、单品页）被访问的次数，一个人在统计时间内访问多次记为多次。" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.viewsCount || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.viewsCount || 0}}</span></div>
                </div>
                <div class="flex-1"></div>
                <div class="flex-1"></div>
                <div class="flex-1"></div>
                <div class="first-trapezoid text-center">
                  <span class="trapezoid-span">访客</span>
                </div>
              </div>
              <div class="trade-item-wrap" style="background-color: #ccf7e5;">
                <div class="flex-1">
                  <div class="f18">
                    <span>下单人数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，成功下单的客户数，一人多次下单记为一人（包含退款订单）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.createOrderPeopleNum || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.createOrderPeopleNum || 0}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>下单笔数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，成功下单的订单笔数（包含退款订单）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.createOrderNum || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.createOrderNum || 0}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>下单金额（元）</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，成功下单的订单金额（包含退款订单）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.createOrderMoney || 0.00}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.createOrderMoney || 0.00}}</span></div>
                </div>
                <div class="flex-1"></div>
                <div class="flex-1"></div>
                <div class="second-trapezoid text-center">
                  <span class="trapezoid-span">下单</span>
                </div>
              </div>
              <div class="trade-item-wrap" style="background-color: #fdebd3;">
                <div class="flex-1">
                  <div class="f18">
                    <span>支付人数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，下单并且支付成功的客户数，一人多次付款记为一人（包含退款订单）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.payOrderPeopleNum || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.payOrderPeopleNum || 0}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>支付订单数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，成功支付的订单数，一个订单对应唯一一个订单号（包含退款订单）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.payOrderNum || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.payOrderNum || 0}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>支付金额（元）</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，所有支付订单金额之和" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.payOrderMoney || 0.00}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.payOrderMoney || 0.00}}</span></div>
                </div>
                <div class="flex-1">
                  <div class="f18">
                    <span>支付件数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，成功支付的商品件数（包含退款商品）" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f30 color-orange">{{theDateBasicData.payGoodsNum || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.payGoodsNum || 0}}</span></div>
                </div>
                <div class="flex-1"></div>
                <div class="third-trapezoid text-center">
                  <span class="trapezoid-span">支付</span>
                </div>
              </div>
            </div>
            <div class="right inline-block" style="width:20%;height: 470px;position: absolute;right: 0;">
              <div class="" style="position: absolute;width: 75px;text-align: center;left: 80px;top: 120px;">
                <div>访问-下单转化率</div>
                <div>{{theDateBasicData.visitorCreateOrderRatio || 0}}%</div>
              </div>
              <div class="" style="position: absolute;width: 75px;text-align: center;left: 30px;bottom: 120px;">
                <div>下单-支付转化率</div>
                <div>{{theDateBasicData.createOrderPayRatio || 0}}%</div>
              </div>
              <div class="" style="position: absolute;width: 75px;text-align: center;left: 180px;top: 50%;">
                <div>访问-支付转化率</div>
                <div>{{theDateBasicData.visitorPayOrderRatio || 0}}%</div>
              </div>
            </div>
          </div>
          <span class="f12 color-gray">名词解释：访问-下单转化率：统计时间内下单人数 / 访客数；下单-支付转化率：统计时间内支付人数 / 下单人数；访问-支付转化率：统计时间内支付人数 / 访客数</span>
        </div>
        <div class="div-item">
          <span>交易概况及趋势</span>
        </div>
        <div class="div-item" title="交易概况及趋势 的折线图" style="height: 400px;">
          <div id="tradeLineChart" style="width: 100%;height: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import dayjs from 'dayjs'
  import {isEmpty,getTimeTypeArray} from '../../../assets/js/utils'
  import {URL} from '../../../config'

  export default {
    name: "tradeDataProfile",
    data(){
      return {
        timeTypeArray:getTimeTypeArray(),
        searchData:{
          startTime: dayjs().startOf('day').subtract(1,'day').format('YYYY-MM-DD'),
          timeType:"1",
        },
        tradeLineChart:{},
        tradeLineChartData:{
          xAxis:[],
          createOrderPeopleNum:[],
          payOrderPeopleNum:[],
          payGoodsNum:[],
        },
        theDateKernelStatisticData:{},
        lastDateKernelStatisticData:{},
        theDateBasicData:{},
        lastDateBasicData:{},
        updateTimeStr:"",
      }
    },
    methods:{
      drawTradeLine(){
        // 基于准备好的dom，初始化echarts实例
        this.tradeLineChart = this.$echarts.init(document.getElementById('tradeLineChart'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['下单人数', '支付人数', '支付件数']
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: 'auto',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.tradeLineChartData.xAxis
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '下单人数',
              type: 'line',
              smooth: true,
              data: this.tradeLineChartData.createOrderPeopleNum
            },
            {
              name: '支付人数',
              type: 'line',
              smooth: true,
              data: this.tradeLineChartData.payOrderPeopleNum
            },
            {
              name: '支付件数',
              type: 'line',
              smooth: true,
              data:  this.tradeLineChartData.payGoodsNum
            },
          ]
        };

        // 绘制图表
        this.tradeLineChart.setOption(option);
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {};
        data = Object.assign(data,this.searchData);
        let startTime = this.searchData.startTime;
        let timeType = this.searchData.timeType;
        if(timeType == 1){//自然日
          if(!isEmpty(startTime)){
            startTime = new Date(startTime + " 00:00:00").getTime();
          }
        }else if( timeType == 2){//自然周
          let weekObj = new Date(startTime);
          let date = weekObj.getDay();
          if(date == 1){
            startTime = weekObj.getTime();
          }else{
            startTime = weekObj.getTime() - 24*60*60*1000;
          }
        }else if(timeType == 3){
          startTime = dayjs(new Date(startTime)).startOf("M").format("YYYY-MM-DD");
          startTime = new Date(startTime + " 00:00:00").getTime();
        }

        data.startTime = startTime;
        return data;
      },
      changeTime(){
        this.getStatisticData();
      },
      changeTimeType(){
        let timeType = this.searchData.timeType;
        this.searchData.startTime = this.timeTypeArray[timeType-1].value;
        this.getStatisticData();
      },

      getKernelStaticData(){
        return new Promise((resolve, reject) => {
          let data = {};
          this.loading = true;
          this.axios.get(URL.suppStatistic.kernelStatistic,{params:data}).then(res=>{
            this.theDateKernelStatisticData = res.data.theDate;
            this.lastDateKernelStatisticData = res.data.lastDate;
            this.updateTimeStr = dayjs().format("YYYY-MM-DD HH:mm:ss");
            resolve(res);
          }).catch(res=>{
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      getStatisticData(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppStatistic.tradeStatistic,{params:data}).then(res=>{
            this.theDateBasicData = res.data.basics.theDate;
            this.lastDateBasicData = res.data.basics.lastDate;
            let len = res.data.diagramList.length;
            this.tradeLineChartData.xAxis = [];
            this.tradeLineChartData.createOrderPeopleNum = [];
            this.tradeLineChartData.payOrderPeopleNum = [];
            this.tradeLineChartData.payGoodsNum = [];
            if(len > 0){
              res.data.diagramList.forEach(item=>{
                this.tradeLineChartData.xAxis.push(item.dateStr);
                this.tradeLineChartData.createOrderPeopleNum.push(item.createOrderPeopleNum);
                this.tradeLineChartData.payOrderPeopleNum.push(item.payOrderPeopleNum);
                this.tradeLineChartData.payGoodsNum.push(item.payGoodsNum);
              });
            }
            this.drawTradeLine();
            resolve(res);

          }).catch(res=>{
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
    },
    created(){
      this.getKernelStaticData()
    },
    mounted(){
      this.getStatisticData();
      window.onresize = ()=>{
        this.tradeLineChart.resize();
      }
    },
  }
</script>

<style lang="less" scoped>
  @import "../../../assets/css/defined";
  .grid-content-div {
    .div-item {
      margin: 20px auto;
    }
    .line-border {
      border: 1px solid #ebebeb;
      border-radius: 3px;
      color: #606266;
      font-size: 14px;
    }
  }
  .color-orange {
    color: orange;
  }
  .color-gray{
    color: gray;
  }
  .f12 {
    font-size: 12px;
  }
  .f18 {
    font-size: 18px;
  }
  .f24 {
    font-size: 24px;
  }
  .f30 {
    font-size: 20px;
  }
  .trade-item-wrap{
    height: 150px;margin-bottom: 5px;border-radius: 5px;position: relative;display: flex;text-align: center;
    .flex-1{
      padding: 30px 5px;
    }
  }
  .first-trapezoid{
    border-top: 150px solid #03a7f0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 0;
    width: 250px;
    position: absolute;
    right: -150px;
  }
  .second-trapezoid{
    border-top: 150px solid #03d77e;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 0;
    width: 150px;
    position: absolute;
    right: -100px;
  }
  .third-trapezoid{
    border-top: 150px solid #f59a24;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 0;
    width: 50px;
    position: absolute;
    right: -50px;
  }
  .trapezoid-span{
    position: absolute;top: -150px;left:0;right: 0;height: 150px;line-height: 150px;color: white;font-size: larger;font-weight: bolder;
  }
  .new-border-test{
    @borderWidth:2px;
    position: absolute;
    z-index: 0;
    border-right: @borderWidth solid #c2c2c2;
    &:before,
    &:after{
      content: '';
      position: absolute;
      height: @borderWidth;
      z-index: 1;
      right: 0;
      background-color: #c2c2c2;
    }
    &:before{
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    .arrows-left{
      .border-arrows-left(10px,10px,#c2c2c2);
      position: absolute;
      z-index: 3;
      left: 120px;
      bottom: -(10px/2);
    }
    &:nth-child(1){
      .defined-new-border(400px,-250px,40px);
      &:before{
        width: 100px;
      }
      &:after {
        width: 220px;
      }
      .arrows-left{
        left: 170px;
      }
    }
    &:nth-child(2){
      .defined-new-border(350px,-150px,100px,250px);
      &:before{
        width: 20px;
      }
      &:after {
        width: 50px;
      }
      .arrows-left{
        left: 290px;
      }
    }
    &:nth-child(3){
      .defined-new-border(350px,-100px,250px,100px);
      &:before{
        width: 20px;
      }
      &:after {
        width: 50px;
      }
      .arrows-left{
        left: 290px;
      }
    }
  }
  .defined-new-border(@width,@right,@topScape,@bottomScape:@topScape){
    width: @width;
    right: @right;
    top: @topScape;
    bottom: @bottomScape;
  }
</style>
